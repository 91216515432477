import { AlertLevels, EditablePerson } from "@/types/Person";

// const FIELDS = ["id", "nameSurname", "email"];

export class IndiceAsistentes {
  id: Record<string, string[]>;
  nameSurname: Record<string, string[]>;
  email: Record<string, string[]>;

  reverse: Record<
    string,
    {
      id: string;
      nameSurname: string;
      email: string;
    }
  >;

  private removeIndexesAndGetAffected(key: string): string[] {
    const shouldCheck: string[] = [];
    const reverse = this.reverse[key];
    if (reverse) {
      this.id[reverse.id] = this.id[reverse.id].filter(
        (savedKey) => savedKey !== key
      );
      this.nameSurname[reverse.nameSurname] = this.nameSurname[
        reverse.nameSurname
      ].filter((savedKey) => savedKey !== key);
      this.email[reverse.email] = this.email[reverse.email].filter(
        (savedKey) => savedKey !== key
      );

      shouldCheck.push(
        ...(this.id[reverse.id] || []),
        ...(this.nameSurname[reverse.nameSurname] || []),
        ...(this.email[reverse.nameSurname] || [])
      );
      return shouldCheck;
    }
    return [];
  }

  index(person: EditablePerson, key: string) {
    if (person.deleted) {
      return;
    }
    const reverseIndex = this.createReverseIndexObject(person);

    this.reverse[key] = reverseIndex;
    this.id[reverseIndex.id] = [...(this.id[reverseIndex.id] || []), key];
    this.nameSurname[reverseIndex.nameSurname] = [
      ...(this.nameSurname[reverseIndex.nameSurname] || []),
      key,
    ];
    this.email[reverseIndex.email] = [
      ...(this.email[reverseIndex.email] || []),
      key,
    ];
  }

  private createReverseIndexObject(
    person: EditablePerson
  ): {
    id: string;
    nameSurname: string;
    email: string;
  } {
    return {
      id: person.id,
      nameSurname: `${person.surname}, ${person.name}`,
      email: person.email,
    };
  }

  checkAndIndex(
    person: EditablePerson,
    key: string,
    people: Record<string, EditablePerson>
  ) {
    const shouldCheck: string[] = this.removeIndexesAndGetAffected(key);
    this.checkPerson(person, key);
    this.index(person, key);
    shouldCheck.forEach((key) => {
      this.checkPerson(people[key], key);
    });
  }

  private checkPerson(person: EditablePerson, key: string) {
    const reverseIndex = this.createReverseIndexObject(person);
    person.alerts = [];
    if (person.deleted) {
      return;
    }
    if (
      (this.id[reverseIndex.id] || []).filter((savedKey) => savedKey !== key)
        ?.length
    ) {
      person.alerts.push({
        key: "id",
        level: AlertLevels.error,
        message: `Id ${person.id} repetido. `,
      });
    }

    if (
      (this.nameSurname[reverseIndex.nameSurname] || []).filter(
        (savedKey) => savedKey !== key
      )?.length
    ) {
      person.alerts.push(
        {
          key: "name",
          level: AlertLevels.warning,
          message: `Persona ${person.name} ${person.surname} repetida. `,
        },
        {
          key: "surname",
          level: AlertLevels.warning,
        }
      );
    }

    if (
      (this.email[reverseIndex.email] || []).filter(
        (savedKey) => savedKey !== key
      )?.length
    ) {
      person.alerts.push({
        key: "email",
        level: AlertLevels.warning,
        message: `Email ${person.id} repetido. `,
      });
    }
  }

  constructor() {
    this.id = {};
    this.nameSurname = {};
    this.email = {};
    this.reverse = {};
  }
}
