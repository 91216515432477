var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'form-row': true, deleted: _vm.person.deleted }},[_c('div',{staticClass:"form-group col-md-1"},[(_vm.index === 0)?_c('label',{attrs:{"for":("id_" + _vm.personId)}},[_vm._v("Id")]):_vm._e(),_c('input',{class:_vm.getClasses('id', ['form-control']),attrs:{"type":"text","id":("id_" + _vm.personId),"disabled":_vm.person.deleted},domProps:{"value":_vm.person.id},on:{"change":function ($event) {
          _vm.person.id = $event.target.value;
          this$1.$emit('changed');
          this$1.$forceUpdate();
        }}})]),_c('div',{staticClass:"form-group col-md-2"},[(_vm.index === 0)?_c('label',{attrs:{"for":("name_" + _vm.personId)}},[_vm._v("Nombre")]):_vm._e(),_c('input',{class:_vm.getClasses('name', ['form-control']),attrs:{"type":"text","id":("name_" + _vm.personId),"disabled":_vm.person.deleted},domProps:{"value":_vm.person.name},on:{"change":function ($event) {
          _vm.person.name = $event.target.value;
          this$1.$emit('changed');
          this$1.$forceUpdate();
        }}})]),_c('div',{staticClass:"form-group col-md-3"},[(_vm.index === 0)?_c('label',{attrs:{"for":("surname_" + _vm.personId)}},[_vm._v("Apellidos")]):_vm._e(),_c('input',{class:_vm.getClasses('surname', ['form-control']),attrs:{"type":"text","id":("surname_" + _vm.personId),"disabled":_vm.person.deleted},domProps:{"value":_vm.person.surname},on:{"change":function ($event) {
          _vm.person.surname = $event.target.value;
          this$1.$emit('changed');
          this$1.$forceUpdate();
        }}})]),_c('div',{staticClass:"form-group col-md-3"},[(_vm.index === 0)?_c('label',{attrs:{"for":("email_" + _vm.personId)}},[_vm._v("Email")]):_vm._e(),_c('input',{class:_vm.getClasses('email', ['form-control']),attrs:{"type":"email","id":("email_" + _vm.personId),"disabled":_vm.person.deleted},domProps:{"value":_vm.person.email},on:{"change":function ($event) {
          _vm.person.email = $event.target.value;
          this$1.$emit('changed');
          this$1.$forceUpdate();
        }}})]),_c('div',{staticClass:"form-group col-md-2"},[(_vm.index === 0)?_c('label',{attrs:{"for":("community_" + _vm.personId)}},[_vm._v("Comunidad")]):_vm._e(),_c('select',{staticClass:"form-control",attrs:{"id":("community_" + _vm.personId),"disabled":_vm.person.deleted},on:{"change":function ($event) {
          _vm.person.comunidad = $event.target.value;
          if (_vm.person.comunidad === _vm.comunidades.world) {
            _vm.person.otherComunidad = true;
            _vm.person.comunidad = '';
          } else {
            _vm.person.otherComunidad = false;
          }
          this$1.$emit('changed');
          this$1.$forceUpdate();
        }}},_vm._l((_vm.comunidades),function(comunidad){return _c('option',{key:comunidad,domProps:{"value":comunidad,"selected":_vm.person.comunidad === comunidad ||
          (comunidad === _vm.comunidades.world && _vm.person.otherComunidad)}},[_vm._v(" "+_vm._s(comunidad)+" ")])}),0),(_vm.person.otherComunidad)?_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":_vm.person.deleted},domProps:{"value":_vm.person.comunidad},on:{"change":function ($event) {
          _vm.person.comunidad = $event.target.value;
          _vm.person.otherComunidad =
            !Object.values(_vm.comunidades).includes(_vm.person.comunidad) ||
            _vm.person.comunidad === _vm.comunidades.world;
          this$1.$emit('changed');
          this$1.$forceUpdate();
        }}}):_vm._e()]),_c('div',{staticClass:"form-group col-md-1"},[(_vm.index === 0)?_c('label',{attrs:{"for":("delete_" + _vm.personId)}},[_vm._v("Borrar")]):_vm._e(),_c('div',{staticClass:"form-check text-center"},[_c('input',{staticClass:"form-check-input position-static",attrs:{"type":"checkbox","id":("delete_" + _vm.personId)},domProps:{"value":_vm.person.deleted},on:{"change":function ($event) {
            _vm.person.deleted = $event.target.checked;
            this$1.$emit('changed');
            this$1.$forceUpdate();
          }}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }