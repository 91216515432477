






// @ is an alias to /src

import Editor from "@/components/Editor.vue";

export default {
  name: "Home",
  components: {
    Editor,
  },
  methods: {},
};
