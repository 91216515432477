
































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  Alert,
  AlertLevels,
  EditablePerson,
  examplePersonas,
  Person,
} from "@/types/Person";
import axios from "axios";
import { fixNamesCapitalization, SECURE_MODE } from "@/helpers/people";
import EditorLine from "@/components/EditorLine.vue";
import { Comunidad, COMUNIDADES } from "@/types/Comunidad";
import { IndiceAsistentes } from "@/helpers/IndiceAsistentes";

let uid = 0;
const DEBUG = false;
@Component({
  components: { EditorLine },
})
export default class Editor extends Vue {
  people: Record<string, Person> = {};
  newPeople: Record<string, EditablePerson> = {};
  saving = false;
  loading = false;
  indiceAsistentes: IndiceAsistentes = new IndiceAsistentes();
  alerts: Alert[] = [];

  static getUid(): string {
    return `${uid++}`;
  }

  created(): void {
    this.load();
  }

  load() {
    this.loading = true;
    (DEBUG
      ? Promise.resolve({ data: examplePersonas })
      : axios.get<Person[]>(
          "https://id41bdeo1j.execute-api.eu-west-1.amazonaws.com/latest/users"
        )
    )
      .then((response) => {
        const people: Record<string, Person> = {};
        const newPeople: Record<string, EditablePerson> = {};

        response.data
          .map((person) => fixNamesCapitalization(person))
          .forEach((person) => {
            const uid = Editor.getUid();
            people[uid] = person;
            newPeople[uid] = {
              ...new EditablePerson(),
              ...person,
              otherComunidad:
                person.comunidad === Comunidad.world ||
                !Object.values(Comunidad).includes(person.comunidad),
            };
          });
        this.people = people;
        this.newPeople = newPeople;

        Object.entries(this.newPeople).forEach((entry) => {
          this.indiceAsistentes.index(entry[1], entry[0]);
        });
        Object.entries(this.newPeople).forEach((entry) => {
          this.indiceAsistentes.checkAndIndex(
            entry[1],
            entry[0],
            this.newPeople
          );
        });

        this.loading = false;
      })
      .catch((reason) => {
        console.log(reason);
        this.alerts.push({
          level: AlertLevels.error,
          message: "No se pudo cargar el listado de asistentes. ",
        });
      });
  }

  add() {
    this.newPeople[Editor.getUid()] = new EditablePerson();
    this.$forceUpdate();
  }

  save() {
    if (
      Object.values(this.newPeople).some((person) =>
        person.alerts.some((alert) => alert.level === AlertLevels.error)
      )
    ) {
      const errorMsg = Object.values(this.newPeople)
        .find((person) =>
          person.alerts.some((alert) => alert.level === AlertLevels.error)
        )
        ?.alerts.find((alert) => alert.level === AlertLevels.error)?.message;
      this.alerts.push({
        level: AlertLevels.error,
        message:
          "Existen errores. Por favor revise antes de guardar: " + errorMsg,
      });
      return;
    }
    this.saving = true;
    if (SECURE_MODE) {
      const comunidades = [...COMUNIDADES];

      const saveNext = () => {
        return new Promise((resolve, reject) => {
          const comunidad = comunidades.shift();
          if (comunidad) {
            this.saveComunidad(comunidad)
              .then(() => {
                saveNext().then(() => {
                  resolve();
                });
              })
              .catch((error) => {
                console.error(error);
                reject();
              });
          } else {
            resolve();
          }
        });
      };

      saveNext()
        .then(() => {
          this.alerts.push({
            level: AlertLevels.info,
            message: "Listado guardado. ",
          });
          this.saving = false;
        })
        .catch((error) => {
          console.error(error);
          this.alerts.push({
            level: AlertLevels.error,
            message: "Error al guardar el listado. ",
          });
        });
    } else {
      axios
        .put(
          "https://id41bdeo1j.execute-api.eu-west-1.amazonaws.com/latest/users",
          Object.values(this.newPeople)
            .filter((person) => !person.deleted)
            .map(
              (person): Person => ({
                id: person.id,
                name: person.name,
                surname: person.surname,
                email: person.email,
                comunidad: person.comunidad,
              })
            )
        )
        .then(() => {
          this.alerts.push({
            level: AlertLevels.info,
            message: "Listado guardado. ",
          });
          this.saving = false;
        })
        .catch((error) => {
          console.error(error);
          this.alerts.push({
            level: AlertLevels.error,
            message: "Error al guardar el listado. ",
          });
        });
    }
  }

  private saveComunidad(comunidad: Comunidad): Promise<void> {
    return axios.put(
      "https://id41bdeo1j.execute-api.eu-west-1.amazonaws.com/latest/users/comunidad/" +
        encodeURI(comunidad),
      Object.values(this.newPeople)
        .filter((person) => !person.deleted)
        .filter((person) => {
          if (comunidad === Comunidad.world) {
            return (
              !COMUNIDADES.includes(person.comunidad) ||
              person.comunidad === Comunidad.world
            );
          } else {
            return comunidad === person.comunidad;
          }
        })
        .map(
          (person): Person => ({
            id: person.id,
            name: person.name,
            surname: person.surname,
            email: person.email,
            comunidad: person.comunidad,
          })
        )
    );
  }

  showModal(): boolean {
    return this.alerts.length > 0;
  }
  getModalTitle(): string {
    if (this.showModal()) {
      switch (this.alerts[this.alerts.length - 1].level) {
        case AlertLevels.error:
          return "Error";
        case AlertLevels.warning:
          return "Alerta";
        default:
          return "Información";
      }
    } else {
      return "";
    }
  }

  getModalContent(): string {
    if (this.showModal()) {
      return this.alerts[this.alerts.length - 1].message;
    } else {
      return "";
    }
  }

  closeModal(): void {
    this.alerts.pop();
  }

  changed(person: EditablePerson, uid: string) {
    Object.keys(person).forEach((key) => {
      if (
        key === "id" ||
        key === "name" ||
        key === "surname" ||
        key === "email"
      ) {
        if (person[key] !== person[key].trim()) {
          person[key] = person[key].trim();
        }
      }

      if (key === "comunidad" && person.comunidad.trim() !== person.comunidad) {
        person.comunidad = person.comunidad.trim() as Comunidad;
      }
    });

    this.indiceAsistentes.checkAndIndex(person, uid, this.newPeople);
    if (person.alerts.length > 0) {
      this.$forceUpdate();
    }
  }
}
