














































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ObjectAlert, AlertLevels, EditablePerson } from "@/types/Person";
import { Comunidad } from "@/types/Comunidad";

@Component
export default class EditorLine extends Vue {
  @Prop() person!: EditablePerson;
  @Prop() index!: number;
  @Prop() uid!: string;
  @Prop() personId!: string;
  private comunidades = Comunidad;

  getClasses(
    field: keyof EditablePerson,
    initialClasses: string[]
  ): Record<string, boolean> {
    const classes: Record<string, boolean> = {};
    initialClasses.forEach((cls) => {
      classes[cls] = true;
    });
    const level = this.person.alerts.reduce(
      (level: AlertLevels, alert: ObjectAlert<EditablePerson>) => {
        if (alert.key === field && alert.level > level) {
          return alert.level;
        }
        return level;
      },
      AlertLevels.none
    );
    if (level >= AlertLevels.warning) {
      switch (level) {
        case AlertLevels.warning:
          classes["is-invalid"] = true;
          break;
        case AlertLevels.error:
          classes["is-invalid"] = true;
          break;
      }
    }
    return classes;
  }
}
